import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import { StaticPage } from '../types/PgPages'

export const JWLabelPage = ({ location }: StaticPage) => {
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const labelId = params.get('labelId')
    const labelUrl = params.get('labelUrl')
    const slugLocation = params.get('custom_location')
    const productSku = params.get('custom_sku')
    const labelUrlIsValid = /https?:\/\//g.test(labelUrl ?? '') && labelUrl?.endsWith('.png')

    if (
      typeof labelId === 'string' &&
      typeof labelUrl === 'string' &&
      typeof slugLocation === 'string' &&
      typeof productSku === 'string' &&
      labelUrlIsValid
    ) {
      navigate(`/${slugLocation}/products/${productSku}?labelId=${labelId}&labelUrl=${labelUrl}`)
    } else navigate('/')
  }, [location])

  return (
    <>
      <Helmet>
        <title>Etiqueta Personalizada Johnnie Walker</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div />
    </>
  )
}

export default JWLabelPage
